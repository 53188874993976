import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelList, ChannelHeader, MessageList, MessageInput, Window } from 'stream-chat-react';
import { setHasChatAccount, setStreamChatToken, setUser } from '../redux_slices/userSlice';
import { API_HOST } from '../config/config';

const Test2 = () => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const user = useSelector((state) => state.user);
  const authToken = localStorage.getItem("authToken");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const providerId = queryParams.get('id');
  const apiKey = 'tpt3e9cy94h9';

  useEffect(() => {
    const fetchUserToken = async () => {
      if (user) {
        const response = await fetch(`${API_HOST}/chat/createChatAccountTest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            userId: providerId,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setUserToken(data.data);
        }
      }
    };

    fetchUserToken();
  }, [user, providerId, authToken, dispatch]);

  useEffect(() => {
    const initClient = async () => {
      if (user && userToken) {
        const chatClient = StreamChat.getInstance(apiKey);
        console.log("connecting user ! ");

        await chatClient.connectUser(
          {
            
            id: providerId,
            name: "david",
            
          },
          userToken
        );

        const filters = { members: { $in: [providerId] } };
        const sort = { last_message_at: -1 };
        const options = { limit: 10 };

        const channels = await chatClient.queryChannels(filters, sort, options);
        if (channels.length > 0) {
          const activeChannel = channels[0];
          await activeChannel.watch();
          setChannel(activeChannel);
        }

        setClient(chatClient);
      }
    };

    if (userToken) {
      initClient();
    }
  }, [user, userToken, providerId]);

  const updateActiveChannel = async (selectedChannel) => {
    console.log("Updating channel:", selectedChannel);
    await selectedChannel.watch();
    setChannel(selectedChannel);
  };

  if (!client) return <div>Loading...</div>;

  return (
    <Chat client={client} theme="messaging light">
      <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{ width: '300px', borderRight: '1px solid #ccc' }}>
          <ChannelList
            filters={{ members: { $in: [providerId] } }}
            sort={{ last_message_at: -1 }}
            options={{ limit: 10 }}
          />
        </div>
        <div style={{ flex: 1 }}>
          {channel && (
            <Channel>
              <Window>
                <ChannelHeader />
                <MessageList />
                <MessageInput />
              </Window>
            </Channel>
          )}
        </div>
      </div>
    </Chat>
  );
};

export default Test2;