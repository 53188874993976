import React, { useState, useEffect } from 'react';
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageList, MessageInput, Thread, Window, ChannelList, ChannelPreviewMessenger } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';

const apiKey = '8k6g837cvx3q';
const userName = 'cold3';
const userId = 'john_userid';
const userToken = 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiam9obl91c2VyaWQiLCJpYXQiOjE3Mzk0NjQwNDEsImlzcyI6IlN0cmVhbSBDaGF0IEphdmEgU0RLIiwic3ViIjoiU3RyZWFtIENoYXQgSmF2YSBTREsifQ.ft_Tyz-QP6TY9W99jvBLk_eAV1pdi_ZYA0c-IPH7h6k';

const user = {
  id: userId,
  image: `https://getstream.io/random_png/?name=${userName}`,
};

const Test = () => {
  const [client, setClient] = useState(null);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const initClient = async () => {
      const chatClient = StreamChat.getInstance(apiKey);
      await chatClient.connectUser(user, userToken);

      const filters = { members: { $in: [userId] } };
      const sort = { last_message_at: -1 };
      const options = { limit: 10 };

      const channels = await chatClient.queryChannels(filters, sort, options);
      if (channels.length > 0) {
        const activeChannel = channels[0];
        await activeChannel.watch();
        setChannel(activeChannel);
      }

      setClient(chatClient);
    };

    initClient();

    return () => {
      if (client) client.disconnectUser();
    };
  }, []);

  const updateActiveChannel = async (selectedChannel) => {
    console.log("Updating channel:", selectedChannel);
    await selectedChannel.watch();
    setChannel(selectedChannel);
  };

  
  if (!client) return <div>Loading...</div>;

  return (
    <Chat client={client} theme="messaging light">
      <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{ width: '300px', borderRight: '1px solid #ccc' }}>
          <ChannelList
              // Preview={CustomChannelPreview}
            filters={{ members: { $in: [userId] } }}
            sort={{ last_message_at: -1 }}
            options={{ limit: 10 }}
          />
        </div>
        <div style={{ flex: 1 }}>
          {channel && (
            <Channel>
              <Window>
                <ChannelHeader title={channel.data.name || 'Channel'} />
                <MessageList />
                <MessageInput />
              </Window>
              <Thread />
            </Channel>
          )}
        </div>
      </div>
    </Chat>
  );
};

export default Test;