import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Record from "../Record/Record";
import { setSelectedRecords } from "../redux_slices/selectedRecordsSlice";
import { useDispatch, useSelector } from "react-redux";
import { API_HOST } from "../config/config";
import ListProsHeader from "./ListProsHeader";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  "&:hover": {
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
    transform: "translateY(-4px)",
  },
  backgroundColor: "#ffffff",
  margin: "10px 0", // Add this line
}));

const ShortMessage = ({ sendAction, userName }) => {
  const selectedRecords = useSelector((state) => state.selectedRecordIds); // Moved to the top level
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [records, setRecords] = useState([]);

  const dispatch = useDispatch();
  const categoryId = useSelector((state) => state.currentRequest.categoryId);
  const areaId = useSelector((state) => state.currentRequest.areaId);
  // const temp = useSelector((state) => state.currentRequest);


  useEffect(() => {
    if(areaId){
      const fetchData = async () => {
        const data = await fetchRecords(categoryId, areaId);
        setRecords(data);
      };
      fetchData();
    }
  }, [categoryId, areaId]);

  const handleSelectedChange = (providerId, isSelected) => {
    if (isSelected) {
      const newSelectedRecords = [...selectedRecords, providerId];
      dispatch(setSelectedRecords(newSelectedRecords));
    } else {
      const newSelectedRecords = selectedRecords.filter(
        (id) => id !== providerId
      );
      dispatch(setSelectedRecords(newSelectedRecords));
    }
  };

  const toggleSelectAll = () => {
    if (!records) return;
    setAreAllSelected((prevAreAllSelected) => {
      const newAreAllSelected = !prevAreAllSelected;

      if (newAreAllSelected) {
        const newSelectedRecords = records.map((record) => record.providerId);
        dispatch(setSelectedRecords(newSelectedRecords));
      } else {
        dispatch(setSelectedRecords([]));
      }

      return newAreAllSelected;
    });
  };

  async function fetchRecords(catgoryId, city) {
    const queryParams = new URLSearchParams({ categoryId });
    if (city) queryParams.append("city", city);

    const response = await fetch(`${API_HOST}/providers?${queryParams}`);
    const data = await response.json();
    return data;
  }

  return (
    <div className=" mx-auto px-4 sm:px-6 lg:px-8">
      {records.length > 0 && (
        <ListProsHeader
          name={userName}
          selectedRecords={selectedRecords}
          areAllSelected={areAllSelected}
          toggleSelectAll={toggleSelectAll}
          sendAction={sendAction}
        />
      )}
      <hr className="border-t border-gray-300 my-8 " />
      <div>
        {records &&
          records.map((record, index) => (
            <div dir="rtl">
              <StyledCard>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <Record
                      key={index}
                      professional={record}
                      isAllSelected={areAllSelected}
                      onSelectedChange={handleSelectedChange}
                      sendAction={sendAction}
                    />
                  </div>
                </Card.Body>
              </StyledCard>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ShortMessage;
