import React from "react";
import SupportIcon from "@mui/icons-material/Support";

const ListProsHeader = ({
    name,
    selectedRecords,
    areAllSelected,
    toggleSelectAll,
    sendAction,
}) => {
    const firstThreeWords = name ? `${name}, העזרה בדרך !` : 'העזרה בדרך !';
    const restOfWords =
        "מצאנו עבורך מספר בעלי מקצוע העונים לבקשה שלך. יש לך אפשרות לשלוח לכל בעל מקצוע בנפרד או לסמן את כולם ולתת לקסם לעשות את שלו";

    
    return (
        <div className="w-full bg-white flex flex-col">
            <div className="flex">
                <div className="w-1/5 flex justify-center place-items-end">
                    <SupportIcon fontSize="large" color="info" />
                </div>
                <div dir="rtl" className="w-4/5 flex flex-col justify-center">
                    <h2 className="text-black text-2xl font-bold text-right m-2">
                        {firstThreeWords}
                    </h2>
                    <h2 className="text-black text-xl text-right m-2">{restOfWords}</h2>
                </div>
            </div>
            <div className="flex justify-end mt-2 mb-6">
                <button
                    onClick={()=>sendAction(null)}
                    className={`px-6 py-2 text-white rounded-full shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 mr-4 ${
                        selectedRecords.length > 0
                            ? "bg-green-500 hover:bg-green-600 focus:ring-green-500"
                            : "bg-green-200 cursor-not-allowed"
                    }`}
                    disabled={selectedRecords.length === 0}
                    title={
                        selectedRecords.length > 0
                            ? `נבחרו ${selectedRecords.length} ספקים`
                            : "בחר לפחות ספק אחד לקבלת הצעת מחיר"
                    }
                >
                    {selectedRecords.length === 0 ? 'שלח לנבחרים' : 
                            selectedRecords.length === 1 ? 'שלח לנבחר': `שלח ל-${selectedRecords.length} הנבחרים`}
                </button>
                <button
                    className="px-6 py-2 bg-blue-700 text-white rounded-full shadow-md hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2"
                    onClick={toggleSelectAll}
                >
                    {areAllSelected ? "נקה בחירה" : "בחר הכל"}
                </button>
            </div>
        </div>
    );
};

export default ListProsHeader;